import ReactDOM from 'react-dom';
import React from "react";
import AdReel from "./Pages/AdReel.js";

ReactDOM.render(   
  <AdReel /> ,
document.getElementById("root")
);

/*import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {Container, NavDropdown, Navbar, Nav, Offcanvas} from 'react-bootstrap'
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import useWindowDimensions from './useWindowDimensions';

import Home from './Pages/Home';
import Games from './Pages/Games';
import Team from './Pages/Team/Team';
import AgeOfUnity from './Pages/Team/Johannes/Portfolio/AgeOfUnity';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";

import reportWebVitals from './reportWebVitals';
import Johannes from './Pages/Team/Johannes/Johannes';
import Ineo from './Pages/Team/Johannes/Portfolio/Ineo';
import Georgios from './Pages/Team/Johannes/Georgios';
const Header = () => {
  const {width, height} = useWindowDimensions()
  const [expanded, setExpanded] = useState(false);
  return (
    <Container style={{fontSize:"20pt"}}>
      {
        width > 1000&&
      <Navbar className='border-bottom border-light' bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img src="logo_big.png" width={128}/>
          </Navbar.Brand>
          <Nav className="me-auto">
          <NavDropdown menuVariant='dark' onClick={() => setExpanded(expanded ? false : "expanded")} show={expanded} title="Portfolio" id="navbarScrollingDropdown">
            <Link to="projects" className="dropdown-item">Moody Sloth</Link>
            <NavDropdown.Divider />
            <Link to="johannes" className="dropdown-item">Johannes</Link>
            <Link to="georgios" className="dropdown-item">Georgios</Link>
        </NavDropdown>
            <Link to="team" className="nav-link" onClick={() => setExpanded(false)}>Team</Link>   
          </Nav>
        </Container>
      </Navbar>
      }
      {
        width <= 1000&&
        <Navbar expanded={expanded} className='border-bottom border-light' variant='dark' bg="dark" expand={false}>
  <Container fluid>
    <Navbar.Brand href="/">
            <img src="logo_big.png" width={128}/></Navbar.Brand>
    <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"      
    >
      <Offcanvas.Header onHide={() => setTimeout(() => {setExpanded(false)}, 150)} className='bg-dark text-light' closeVariant='white' closeButton>
        <Offcanvas.Title id="offcanvasNavbarLabel">Moody Sloth</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <Link onClick={() => setTimeout(() => {setExpanded(false)}, 150)}  to="/" className="nav-link text-dark">Home</Link>  
          <NavDropdown bsPrefix='text-dark nav-link dropdown-toggle' title="Portfolio" id="navbarScrollingDropdown">
            <Link onClick={() => setTimeout(() => {setExpanded(false)}, 150)}  to="projects" className="dropdown-item">Moody Sloth</Link>
            <NavDropdown.Divider />
            <Link onClick={() => setTimeout(() => {setExpanded(false)}, 150)}  to="johannes" className="dropdown-item">Johannes</Link>
            <Link onClick={() => setTimeout(() => {setExpanded(false)}, 150)}  to="georgios" className="dropdown-item">Georgios</Link>
        </NavDropdown>
          <Link onClick={() => setTimeout(() => {setExpanded(false)}, 150)}  to="team" className="nav-link text-dark">Team</Link>  
        </Nav>        
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>
      }      
    </Container>
    );
};
ReactDOM.render(
  <BrowserRouter>
  <Header/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="projects" element={<Games />} />
      <Route path="team" element={<Team />}/>
      <Route path="johannes" element={<Johannes />} />
      <Route path="georgios" element={<Georgios />} />
      <Route path="ageofunity" element={<AgeOfUnity />} />
      <Route path="ineo" element={<Ineo />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
*/