import React from "react";
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import TinyDefense1 from "../Ads/TinyDefense1.mp4";
import GateMaster from "../Ads/GateMaster.mp4";
import TinyDefense3 from "../Ads/TinyDefense3.mp4";

export default function AdReel() {
    const videos = 
    [
        TinyDefense1,
        GateMaster,
        TinyDefense3,
    ]
    const [videoId, setVideoId] = useState(0);
    const nextVideoCallback = () => 
    {
        console.log((videoId+1)%videos.length);
        setVideoId((videoId+1)%videos.length);
    }
    return (
        <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
            <video style={{position: "absolute", top: "12vh", height:"50vh", width: '40vh', borderRadius: "2vh", boxShadow: "0vh 0vh 1vh 1vh #B0B0B0"}} autoPlay muted onEnded={nextVideoCallback} src={videos[videoId]}/>
            
            <Button className="rounded-pill" variant="dark" href="https://play.google.com/store/apps/dev?id=6004558978706359870" style={{position:"absolute", top: '66vh'}} 
        >
            <img style={{margin: "1.5vh"}} width='150vh' src="Logos/GooglePlay.png"/>
        </Button>
            <div style={{justifyContent: 'space-evenly', position:"absolute", bottom: 0, display: 'flex', width:'50vh'}}>
                <img width='70vh' src="Logos/Lion.png"/>
                <img width='70vh' src="Logos/Voodoo.svg" color="black"/>
                <img width='70vh' src="Logos/CrazyLabs.png"/>                
            </div>
        </div>
    );
    //<img src="Ads/phone.png" style={{width: '28vh', position: "absolute"}}/>
    //<img src="Ads/phoneBackground.png" style={{width: '28vh', position: "absolute"}}/>
}